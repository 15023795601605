import { Button } from "@www/ui/button";
import type { MetaFunction } from "react-router";
import { Link } from "react-router";

export const meta: MetaFunction = () => {
  return [
    { title: "Business Dashboard" },
    { name: "description", content: "SWAG MONEY YOLO" },
  ];
};

export default function Index() {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center space-y-4">
      <h1 className="text-2xl font-bold">Adcept Business Dashboard</h1>
      <Link to="/flow-selector">
        <Button>Get started</Button>
      </Link>
    </div>
  );
}
